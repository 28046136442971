import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import MainLayout from './layouts/MainLayout'

import Starter from './pages/Starter'
import Step1 from './pages/Step1'
import Step2 from './pages/Step2'
import Step3 from './pages/Step3'
import Step4 from './pages/Step4'
import Complete from './pages/Complete'
import Clarity from '@microsoft/clarity'
import ReactGA from 'react-ga4'
// import { useEffect } from 'react'
ReactGA.initialize('G-FE6JTJX4SW')

function App() {
  const projectId = 'pifyc45aob'
  if (process.env.REACT_APP_ENV === 'production') {
    Clarity.init(projectId)
  }
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Starter />} />
          <Route path="/step1" element={<Step1 />} />
          <Route path="/step2" element={<Step2 />} />
          <Route path="/step3" element={<Step3 />} />
          <Route path="/step4" element={<Step4 />} />
          <Route path="/complete" element={<Complete />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App
